/* Icon Pulse Shrink */
@include keyframes(#{$nameSpace}-icon-pulse-shrink) {
	to {
		@include prefixed(transform, scale(.8));
	}
}

@mixin icon-pulse-shrink {
	@include hacks();
	
	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-pulse-shrink);
			@include prefixed(animation-duration, $mediumDuration);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, infinite);
			@include prefixed(animation-direction, alternate);
		}
	}
}
