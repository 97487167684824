// ==========================================================================
// [typography]
// ==========================================================================

$merriweather: 	'Merriweather', Georgia, 	serif;
$yesevaone: 	'Yeseva One', 	Georgia, 	serif;
$unicaone: 		'Unica One', 	Arial, 		sans-serif;
$open-sans: 	'Open Sans', 	Arial, 		sans-serif;
$rubik: 		'Rubik', 		Arial, 		sans-serif;



// ==========================================================================
// [colors]
// ==========================================================================

$red:			#ff3c36;
$purple: 		#E51A7B;
$deep-blue: 	#567fbf;
$blue:			#2f549b;
$link-blue: 	#337ab7;
