// ==========================================================================
// [common]
// ==========================================================================
body {
	// background-color: #eee;
}

.container-fluid {
	position: relative;
	z-index: 10;
}

h3 {
	font-weight: 900;

	&.center {
		text-align: center;
	}

	&.caps {
		text-transform: uppercase;
	}
}

.btn-sm {
	border-radius: 1rem;
	padding-left: 1em;
	padding-right: 1em;

	& i {
		font-size: smaller;
		padding-right: .8em;
	}
}

b {
	font-weight: 700;
}




// ==========================================================================
// header
// ==========================================================================

.top-header {
	position: absolute;
	left: 0;
	top: 0;
	height: 60px;
	width: 100%;
	z-index: 15;
	background-color: #fff;
	box-shadow: 0px 0px 3px 0px #333;

	& img {
		display: block;
		margin: 0 auto;
		margin-top: 10px;
		height: 40px;
		width: auto;
	}
}

nav.navbar {
	position: absolute;
	top: 0;
	z-index: 20;
	width: 100%;
}



// ==========================================================================
// header
// ==========================================================================

header {

	.rainbow-flag 			{ height: 1vh; }
	.rainbow-flag-red 		{ background-color: #E70000; }
	.rainbow-flag-orange 	{ background-color: #FF8C00; }
	.rainbow-flag-yellow 	{ background-color: #FFEF00; }
	.rainbow-flag-green 	{ background-color: #00811F; }
	.rainbow-flag-blue 		{ background-color: #0044FF; }
	.rainbow-flag-purple 	{ background-color: #760089; }

	& .video-header {
		position: relative;
		overflow: hidden;
		height: 50vh;

		@media screen and (min-width: 576px) {
			height: 99vh;
		}

		& video {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background-color: black;
			background-position: center center;
			background-size: contain;
			-webkit-filter: brightness(1);
			filter: brightness(0.8);
		}

		& .logo {
			position: absolute;
			z-index: 30;
			width: 400px;
			height: auto;
		}

		& #scroll-down {
			position: absolute;
			width: 60px;
			height: 60px;
			z-index: 20;
			bottom: 50px;
			left: 50%;
			margin-left: -30px;
			opacity: 0.7;
			animation: mousemove 1.2s ease-out forwards;
			animation-iteration-count: infinite;

			@keyframes mousemove {
				from { bottom: 50px; opacity: .7;}
				to { bottom: 20px; opacity: 0;}
			}
		}
	}

}


// ==========================================================================
// .header
// ==========================================================================

.header {
	font-size: 18px;

	& .parallax-window {
		padding-top: 100px;
		padding-bottom: 100px;
		background: transparent;
	}

	p {
		margin-left: 20%;
		margin-right: 15%;
	}
}



// ==========================================================================
// .intro
// ==========================================================================

.intro {

	& .row {
		background-color: #fff;
		padding-top: 100px;
		padding-bottom: 100px;

		& .left-side {
			& .cover-image {
				@extend %cover-image;
				background-image: url('../img/lion.gif');
			}

			& img {
				display: none;
				margin: 0 auto;
				width: 420px;
				height: auto;

				@media screen and (min-width: 992px){
					display: block;
				}
			}
		}

		& .right-side {
			font-size: 16px;
			font-weight: 300;
			line-height: 1.6;
			color: #7e8890;

			& .content {
				padding: 0 50px;

				@media screen and (min-width: 992px) {
					padding: 0 100px 0 0;
				}

			}

			& h3 {
				color: #161C2E;
				margin-bottom: 0.5em;
			}

			& .lion-small {
				display: block;
				margin: 0 auto;
				height: 400px;
				margin-top: 50px;

				@media screen and (min-width: 992px) {
					display: none;
				}
			}
		}
	}

}



// ==========================================================================
// .pillars
// ==========================================================================

.pillars {

	& p.subtitle {
		text-transform: uppercase;
	}

	& .picto {
		width: 50px;
		height: 50px;
		margin-bottom: 25px;
	}

	& h3 {
		line-height: 30px;
		font-size: 30px;
		margin-bottom: 30px;
		text-transform: uppercase;
		font-weight: 900;
	}

	& p.text {
		font-size: 18px;
		color: #666;
		line-height: 1.5;
	}

	& p.text {
		font-size: 18px;
		line-height: 1.5;
	}

	& .content {
		padding: 50px;

		@media screen and (min-width: 992px){
			padding: 100px;
		}
	}

}



// ==========================================================================
// .pillar-1 / Éducation
// ==========================================================================

.pillar-1 {

	.left-side {
		background-color: #eee;

		& .cover-image {
			@extend %cover-image;
			background-image: url('../img/lgbt-4.jpg');
		}
	}

	.right-side {
		background-color: #eee;

		& p.subtitle {
			color: #666;
		}

		& h3 {
			color: #666;
		}

		& p.text {
			color: #666;
		}
	}

}



// ==========================================================================
// .pillar-2 / Appartenance
// ==========================================================================

.pillar-2 {

	.left-side {
		background-color: #161C2E;

		& p.subtitle {
			color: rgba(255,255,255,.2);
		}

		& h3 {
			color: #fff;
		}

		& p.text {
			color: rgba(255,255,255,.5);
		}
	}

	.right-side {
		background-color: #eee;

		& .cover-image {
			@extend %cover-image;
			background-image: url('../img/lgbt-1.jpg');
		}
	}

}



// ==========================================================================
// .pillar-3 / Rencontres
// ==========================================================================

.pillar-3 {

	.left-side {
		background-color: #eee;

		& .cover-image {
			@extend %cover-image;
			background-image: url('../img/lgbt-2.jpg');
		}
	}

	.right-side {
		background-color: #eee;

		& p.subtitle {
			color: #666;
		}

		& h3 {
			color: #666;
		}

		& p.text {
			color: #666;
		}
	}

}


// ==========================================================================
// .pillar-4 / Développement
// ==========================================================================

.pillar-4 {

	.left-side {
		background-color: #161C2E;

		& p.subtitle {
			color: rgba(255,255,255,.2);
		}

		& h3 {
			color: #fff;
		}

		& p.text {
			color: rgba(255,255,255,.5);
		}
	}

	.right-side {
		background-color: #eee;

		& .cover-image {
			@extend %cover-image;
			background-image: url('../img/lgbt-3.jpg');
		}
	}

}



// ==========================================================================
// footer
// ==========================================================================

.nous-rejoindre {
	background-color: #eee;
	color: #7e8890;
	padding: 50px;

	@media screen and (min-width: 992px){
		padding: 100px;
	}

	& h3 {
		color: #161C2E;
		margin-bottom: 30px;
	}
}



// ==========================================================================
// footer
// ==========================================================================
footer {
	padding: 20px 100px;
	background-color: #161C2E;
	color: #fff;

	& .row > div {
		text-align: center;
		font-size: 14px;
	}

	& .bold {
		font-weight: 900;
	}

	& a {
		color: #ffffff !important;

		& i {
			font-size: smaller;
			padding-right: .8em;
			position: relative;
			top: -1px;
		}
	}
}
