/**
 * --------------------------------------------------
 * MIXINS
 *
 * Declaration
 * @mixin function(args) { ... }
 *
 * Usage
 * @include function(arg);
 * --------------------------------------------------
 */

// HR STYLING

@mixin hr($color:#666) {
	border: 0;
    height: 1px;
    background-color: $color;
}

// SIZING SHORTCUTS

@mixin size($width, $height, $unit:px) {
	width: $width + $unit;
	height: $height + $unit;
}

@mixin square($size) {
	@include size($size, $size);
}



// CENTER-ALIGN A BLOCK LEVEL ELEMENT

@mixin center-block() {
	display: block;
	margin-left: auto;
	margin-right: auto;
}



// ABSOLUTE POSITIONNING

@mixin absolutehcenter($left) {
	position: absolute;
	width: $left + px;
	left: 50%;
	margin-left: -($left/2) + px;
}

@mixin absolutevcenter($top) {
	position: absolute;
	height: $top + px;
	top: 50%;
	margin-top: -($top/2) + px;
}

@mixin absolutebothcenter($left, $top) {
	position: absolute;
	width: $left + px;
	height: $top + px;
	left: 50%;
	top: 50%;
	margin-left: -($left/2) + px;
	margin-top: -($top/2) + px;
}



// BACKGROUND SIZE

@mixin background-size($value) {
	-webkit-background-size: $value;
	-moz-background-size: $value;
	-o-background-size: $value;
	background-size: $value;
}



// PLACEHOLDER TEXT

@mixin placeholder {
	&:-moz-placeholder            { @content; } // Firefox 4-18
	&::-moz-placeholder           { @content; } // Firefox 19+
	&:-ms-input-placeholder       { @content; } // Internet Explorer 10+
	&::-webkit-input-placeholder  { @content; } // Safari and Chrome
}



// TEXT OVERFLOW
// Requires inline-block or block for proper styling

@mixin text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}



// RESIZE ANYTHING

@mixin resizable($direction) {
	resize: $direction; 	// Options: horizontal, vertical, both
	overflow: auto; 		// Safari fix
}



// OPACITY

@mixin opacity($opacity) {
	opacity: $opacity;
	// IE8 filter
	$opacity-ie: ($opacity * 100);
	filter: #{alpha(opacity=$opacity-ie)};
}



// RETINA IMAGES
// Short retina mixin for setting background-image and -size

@mixin img-retina($file-1x, $file-2x, $width-1x, $height-1x) {
	background-image: image-url("#{$file-1x}");

	@media
	only screen and (-webkit-min-device-pixel-ratio: 2),
	only screen and (   min--moz-device-pixel-ratio: 2),
	only screen and (     -o-min-device-pixel-ratio: 2/1),
	only screen and (        min-device-pixel-ratio: 2),
	only screen and (                min-resolution: 192dpi),
	only screen and (                min-resolution: 2dppx) {
    	background-image: image-url("#{$file-2x}");
    	background-size: $width-1x $height-1x;
	}
}



// RESPONSIVE IMAGE
// Keep images from scaling beyond the width of their parents.

@mixin img-responsive($display: block) {
	display: $display;
	max-width: 100%;// Part 1: Set a maximum relative to the parent
	height: auto; 	// Part 2: Scale the height according to the width, otherwise you get stretching
}



// FONT SMOOTHING
@mixin font-smoothing($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}



/**
 * --------------------------------------------------
 * PLACEHOLDERS
 *
 * Declaration
 * %placeholder { ... }
 *
 * Usage
 * @extend %placeholder;
 * --------------------------------------------------
 */

// CLEARFIX
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.

%clearfix {
	&:before,
	&:after {
		content: " "; 	/* 1 */
		display: table; /* 2 */
	}

	&:after {
	clear: both;
	}
}



// VISUALLY HIDDEN

%visuallyhidden {
	margin: -1px;
	padding: 0;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
	clip: rect(0, 0, 0, 0);
	position: absolute;
}



// COVER IMAGE

%cover-image {
	width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
	background-repeat: no-repeat;
}
