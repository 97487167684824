/* Icon Push */
@include keyframes(#{$nameSpace}-icon-push) {
	50% {
		@include prefixed(transform, scale(.5));
	}
}

@mixin icon-push {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
		@include prefixed(transition-duration, $mediumDuration);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-timing-function, ease-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-push);
			@include prefixed(animation-duration, $mediumDuration);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, 1);
		}
	}
}
