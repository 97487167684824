/* Icon Buzz */
@include keyframes(#{$nameSpace}-icon-buzz) {
	50% {
		@include prefixed(transform, translateX(3px) rotate(2deg));
	}

	100% {
		@include prefixed(transform, translateX(-3px) rotate(-2deg));
	}
}

@mixin icon-buzz {
	@include hacks();
	@include prefixed(transition-duration, $mediumDuration);

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-buzz);
			@include prefixed(animation-duration, .15s);
			@include prefixed(animation-timing-function, linear);
			@include prefixed(animation-iteration-count, infinite);
		}
	}
}
