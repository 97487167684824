/* Icon Up */
@include keyframes(#{$nameSpace}-icon-up) {
	0%,
	50%,
	100% {
		@include prefixed(transform, translateY(0));
	}
	25%,
	75% {
		@include prefixed(transform, translateY(-6px));
	}
}

/* Icon Up */
@mixin icon-up {
	@include hacks();

	.hvr-icon {
		@include prefixed(transform, translateZ(0));
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(animation-name, #{$nameSpace}-icon-up);
			@include prefixed(animation-duration, .75s);
			@include prefixed(animation-timing-function, ease-out);
		}
	}
}
