/* Icon Spin */
@mixin icon-spin {
	@include hacks();

	.hvr-icon {
		@include prefixed(transition-duration, 1s);
		@include prefixed(transition-property, transform);
		@include prefixed(transition-timing-function, ease-in-out);
	}

	&:hover,
	&:focus,
	&:active {

		.hvr-icon {
			@include prefixed(transform, rotate(360deg));
		}
	}
}
