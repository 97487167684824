/**
 * --------------------------------------------------
 * MIXINS
 *
 * Declaration
 * @mixin function(args) { ... }
 *
 * Usage
 * @include function(arg);
 * --------------------------------------------------
 */
/**
 * --------------------------------------------------
 * PLACEHOLDERS
 *
 * Declaration
 * %placeholder { ... }
 *
 * Usage
 * @extend %placeholder;
 * --------------------------------------------------
 */
.intro .row .left-side .cover-image, .pillar-1 .left-side .cover-image, .pillar-2 .right-side .cover-image, .pillar-3 .left-side .cover-image, .pillar-4 .right-side .cover-image {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}

html {
	height: 100%;
	overflow: -moz-scrollbars-vertical;
	overflow-y: scroll;
}

body {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Rubik", Arial, sans-serif;
}

hr {
	border: 0;
	height: 1px;
	background-color: #666;
}

b {
	font-weight: 700;
}

a {
	color: #337ab7;
	text-decoration: none;
	-webkit-transition: all .2s ease-in;
	-moz-transition: all .2s ease-in;
	-o-transition: all .2s ease-in;
	transition: all .2s ease-in;
}

a:hover {
	text-decoration: none;
	color: #E51A7B !important;
}

a:link, a:visited, a:active {
	color: #337ab7;
	text-decoration: none;
}

ul {
	list-style-position: inside;
}

.container-fluid {
	position: relative;
	z-index: 10;
}

h3 {
	font-weight: 900;
}

h3.center {
	text-align: center;
}

h3.caps {
	text-transform: uppercase;
}

.btn-sm {
	border-radius: 1rem;
	padding-left: 1em;
	padding-right: 1em;
}

.btn-sm i {
	font-size: smaller;
	padding-right: .8em;
}

b {
	font-weight: 700;
}

.top-header {
	position: absolute;
	left: 0;
	top: 0;
	height: 60px;
	width: 100%;
	z-index: 15;
	background-color: #fff;
	box-shadow: 0px 0px 3px 0px #333;
}

.top-header img {
	display: block;
	margin: 0 auto;
	margin-top: 10px;
	height: 40px;
	width: auto;
}

nav.navbar {
	position: absolute;
	top: 0;
	z-index: 20;
	width: 100%;
}

header .rainbow-flag {
	height: 1vh;
}

header .rainbow-flag-red {
	background-color: #E70000;
}

header .rainbow-flag-orange {
	background-color: #FF8C00;
}

header .rainbow-flag-yellow {
	background-color: #FFEF00;
}

header .rainbow-flag-green {
	background-color: #00811F;
}

header .rainbow-flag-blue {
	background-color: #0044FF;
}

header .rainbow-flag-purple {
	background-color: #760089;
}

header .video-header {
	position: relative;
	overflow: hidden;
	height: 50vh;
}

@media screen and (min-width: 576px) {
	header .video-header {
		height: 99vh;
	}
}

header .video-header video {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-color: black;
	background-position: center center;
	background-size: contain;
	-webkit-filter: brightness(1);
	filter: brightness(0.8);
}

header .video-header .logo {
	position: absolute;
	z-index: 30;
	width: 400px;
	height: auto;
}

header .video-header #scroll-down {
	position: absolute;
	width: 60px;
	height: 60px;
	z-index: 20;
	bottom: 50px;
	left: 50%;
	margin-left: -30px;
	opacity: 0.7;
	animation: mousemove 1.2s ease-out forwards;
	animation-iteration-count: infinite;
}

@keyframes mousemove {
	from {
		bottom: 50px;
		opacity: .7;
	}
	to {
		bottom: 20px;
		opacity: 0;
	}
}

.header {
	font-size: 18px;
}

.header .parallax-window {
	padding-top: 100px;
	padding-bottom: 100px;
	background: transparent;
}

.header p {
	margin-left: 20%;
	margin-right: 15%;
}

.intro .row {
	background-color: #fff;
	padding-top: 100px;
	padding-bottom: 100px;
}

.intro .row .left-side .cover-image {
	background-image: url("../img/lion.gif");
}

.intro .row .left-side img {
	display: none;
	margin: 0 auto;
	width: 420px;
	height: auto;
}

@media screen and (min-width: 992px) {
	.intro .row .left-side img {
		display: block;
	}
}

.intro .row .right-side {
	font-size: 16px;
	font-weight: 300;
	line-height: 1.6;
	color: #7e8890;
}

.intro .row .right-side .content {
	padding: 0 50px;
}

@media screen and (min-width: 992px) {
	.intro .row .right-side .content {
		padding: 0 100px 0 0;
	}
}

.intro .row .right-side h3 {
	color: #161C2E;
	margin-bottom: 0.5em;
}

.intro .row .right-side .lion-small {
	display: block;
	margin: 0 auto;
	height: 400px;
	margin-top: 50px;
}

@media screen and (min-width: 992px) {
	.intro .row .right-side .lion-small {
		display: none;
	}
}

.pillars p.subtitle {
	text-transform: uppercase;
}

.pillars .picto {
	width: 50px;
	height: 50px;
	margin-bottom: 25px;
}

.pillars h3 {
	line-height: 30px;
	font-size: 30px;
	margin-bottom: 30px;
	text-transform: uppercase;
	font-weight: 900;
}

.pillars p.text {
	font-size: 18px;
	color: #666;
	line-height: 1.5;
}

.pillars p.text {
	font-size: 18px;
	line-height: 1.5;
}

.pillars .content {
	padding: 50px;
}

@media screen and (min-width: 992px) {
	.pillars .content {
		padding: 100px;
	}
}

.pillar-1 .left-side {
	background-color: #eee;
}

.pillar-1 .left-side .cover-image {
	background-image: url("../img/lgbt-4.jpg");
}

.pillar-1 .right-side {
	background-color: #eee;
}

.pillar-1 .right-side p.subtitle {
	color: #666;
}

.pillar-1 .right-side h3 {
	color: #666;
}

.pillar-1 .right-side p.text {
	color: #666;
}

.pillar-2 .left-side {
	background-color: #161C2E;
}

.pillar-2 .left-side p.subtitle {
	color: rgba(255, 255, 255, 0.2);
}

.pillar-2 .left-side h3 {
	color: #fff;
}

.pillar-2 .left-side p.text {
	color: rgba(255, 255, 255, 0.5);
}

.pillar-2 .right-side {
	background-color: #eee;
}

.pillar-2 .right-side .cover-image {
	background-image: url("../img/lgbt-1.jpg");
}

.pillar-3 .left-side {
	background-color: #eee;
}

.pillar-3 .left-side .cover-image {
	background-image: url("../img/lgbt-2.jpg");
}

.pillar-3 .right-side {
	background-color: #eee;
}

.pillar-3 .right-side p.subtitle {
	color: #666;
}

.pillar-3 .right-side h3 {
	color: #666;
}

.pillar-3 .right-side p.text {
	color: #666;
}

.pillar-4 .left-side {
	background-color: #161C2E;
}

.pillar-4 .left-side p.subtitle {
	color: rgba(255, 255, 255, 0.2);
}

.pillar-4 .left-side h3 {
	color: #fff;
}

.pillar-4 .left-side p.text {
	color: rgba(255, 255, 255, 0.5);
}

.pillar-4 .right-side {
	background-color: #eee;
}

.pillar-4 .right-side .cover-image {
	background-image: url("../img/lgbt-3.jpg");
}

.nous-rejoindre {
	background-color: #eee;
	color: #7e8890;
	padding: 50px;
}

@media screen and (min-width: 992px) {
	.nous-rejoindre {
		padding: 100px;
	}
}

.nous-rejoindre h3 {
	color: #161C2E;
	margin-bottom: 30px;
}

footer {
	padding: 20px 100px;
	background-color: #161C2E;
	color: #fff;
}

footer .row > div {
	text-align: center;
	font-size: 14px;
}

footer .bold {
	font-weight: 900;
}

footer a {
	color: #ffffff !important;
}

footer a i {
	font-size: smaller;
	padding-right: .8em;
	position: relative;
	top: -1px;
}
