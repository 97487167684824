// ==========================================================================
// HTML ELEMENTS
// ==========================================================================

html {
	// font-size: 62.5%; // 10px
	height: 100%;
	overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

body {
	height: 100%;
	@include font-smoothing(on);
	font-family: $rubik;
}

hr {
    @include hr();
}

b {
	font-weight: 700;
}

a {
	color: $link-blue;
	text-decoration: none;
    -webkit-transition: all .2s ease-in;
	   -moz-transition: all .2s ease-in;
         -o-transition: all .2s ease-in;
            transition: all .2s ease-in;

	&:hover {
		text-decoration: none;
		color: $purple !important;
	}

	&:link,
	&:visited,
	&:active {
		color: $link-blue;
		text-decoration: none;
	}
}

ul {
	list-style-position: inside;
}
